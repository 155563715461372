import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { ConnectKitButton, useSIWE, useModal as useConnectKitModal } from 'connectkit'

import { Box, Button, Divider, Flex, Heading, Image, Text, VStack } from '@chakra-ui/react'
import { Link } from '@chakra-ui/next-js'
import { SiweHookProps } from '../types'

const Login = () => {
    const { push } = useRouter()
    const { isSignedIn, data: siweUserData }: SiweHookProps = useSIWE()

    const { openSIWE, setOpen } = useConnectKitModal({
        onConnect() {
            if (isSignedIn) {
                setOpen(false)
            }
        },
    })

    useEffect(() => {
        if (siweUserData?.address) {
            setOpen(false)
            push('/')
        }
    }, [push, setOpen, siweUserData?.address])

    return (
        <Flex
            maxWidth="unset"
            height="100vh"
            bg="white"
            pt={[20, null, 16]}
            pb={8}
            flexDirection="column"
            alignItems="center"
            justify={['center', null, 'space-evenly', 'center']}
            gap={[8, null, null, 8]}
        >
            <Image height={[10, null, 14]} src="/logos/troops-bw.svg" alt="Metamask wallet logo" />
            <VStack spacing={4} w={['75%', '60%', '40%', '25%']}>
                <Flex
                    color="black"
                    height="full"
                    alignItems="center"
                    flexDirection="column"
                    gap={[8, null, 4]}
                    width="100%"
                >
                    <Box>
                        <Heading
                            as="h1"
                            size={['md', null, null, 'sm']}
                            noOfLines={1}
                            textAlign="center"
                        >
                            Login with your wallet
                        </Heading>

                        <Text fontSize={['md', null, null, 'sm']} textAlign="center">
                            Please connect your wallet to continue
                        </Text>
                    </Box>
                    <Flex
                        flexDirection="column"
                        gap={4}
                        width="100%"
                        alignItems="center"
                        py={8}
                        borderRadius="md"
                    >
                        <Flex gap={4}>
                            <ConnectKitButton.Custom>
                                {({ isConnected, show }) => {
                                    return (
                                        <Button
                                            w="100%"
                                            variant="black-outline"
                                            onClick={() => {
                                                if (isConnected && !isSignedIn) {
                                                    openSIWE(true)
                                                } else {
                                                    show?.()
                                                }
                                            }}
                                        >
                                            {Boolean(isSignedIn && siweUserData) ? (
                                                <Text fontSize="md" textAlign="center">
                                                    {siweUserData?.address}
                                                </Text>
                                            ) : (
                                                'Connect wallet'
                                            )}
                                        </Button>
                                    )
                                }}
                            </ConnectKitButton.Custom>
                        </Flex>
                    </Flex>
                </Flex>

                <Divider color="gray.300" width="80%" maxW={['100%', '100%', '2xl', 'xs']} />
                <VStack gap={1}>
                    <Text color="#333" fontSize={['md', null, null, 'sm']}>
                        Are you building a community?
                    </Text>
                    {/*
                        // TODO: Add links
                    */}
                    <Link
                        href="#"
                        color="#0000FF"
                        fontWeight="medium"
                        fontSize={['md', null, null, 'sm']}
                    >
                        Create a Troop for your frens!
                    </Link>
                </VStack>
            </VStack>
            <VStack mt={[6, null, 4, 0]} px={[12, 16]} spacing={4}>
                <Text color="#333" fontSize={['sm', null, null, 'xs']} textAlign="center">
                    By proceeding you agree to the Troops <br />
                    {/*
                        // TODO: Add links
                    */}
                    <Link href="#" color="#0000FF" fontWeight="medium">
                        Terms of Use
                    </Link>
                </Text>
                <Text color="#333" fontSize={['sm', null, null, 'xs']} textAlign="center">
                    Not what you were looking for?{' '}
                    <Link href="/" color="#0000FF" fontWeight="medium">
                        Go back
                    </Link>
                </Text>
            </VStack>
        </Flex>
    )
}

export default Login
